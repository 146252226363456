import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import "./App.css";
import { Link} from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import "./SpaceGrotesk-Medium.ttf";
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import qrcode from "qrcode";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import Paper from '@mui/material/Paper';


const App = () => {
  const [password, setPassword] = useState("**********************");
  const [passwordLength, setPasswordLength] = useState(16);
  const [useSpecialChars, setUseSpecialChars] = useState(true);
  const [useDigits, setUseDigits] = useState(true);
  const [useCapitalLetters, setUseCapitalLetters] = useState(true);
  const [entropy, setEntropy] = useState(0);
  const [estimatedTimeToCrack, setEstimatedTimeToCrack] = useState("Unknown");
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");  // State to store QR code data URL
  // const qrCodeRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const calculateEntropy = () => {
      let charSetSize = 0;

      if (useSpecialChars) charSetSize += 32; 
      if (useDigits) charSetSize += 10; 
      if (useCapitalLetters) charSetSize += 26; 
      charSetSize += 26; 

      const numberOfCombinations = Math.pow(charSetSize, passwordLength);
      const calculatedEntropy = Math.log2(numberOfCombinations);

      setEntropy(calculatedEntropy);

            const guessesPerSecond = 1e9;
            const estimatedSecondsToCrack = Math.pow(2, calculatedEntropy) / guessesPerSecond;
            const estimatedYearsToCrack = estimatedSecondsToCrack / (60 * 60 * 24 * 365);
      
            setEstimatedTimeToCrack(estimatedYearsToCrack.toFixed(2));
          
    };

    calculateEntropy();
  }, [passwordLength, useSpecialChars, useDigits, useCapitalLetters]);

  useEffect(() => {
    // Generate colorful QR Code data URL
    const qrCodeOptions = {
      errorCorrectionLevel: 'H',
      color: {
        dark: '#1a80e5',  // Foreground color
        light: '#121a21'  // Background color
      }
    };

    qrcode.toDataURL(password, qrCodeOptions, (err, dataUrl) => {
      if (err) {
        console.error(err);
        return;
      }
      setQrCodeDataUrl(dataUrl);
    });
  }, [password]);  

  const generatePassword = () => {
    const generateRandomChar = (charSet) => {
      const randomIndex = Math.floor(Math.random() * charSet.length);
      return charSet[randomIndex];
      
    };

    let charSet = "abcdefghijklmnopqrstuvwxyz";
    if (useSpecialChars) charSet += "!@#$%^&*()-_=+[]{}|;:'\",.<>/?";
    if (useDigits) charSet += "0123456789";
    if (useCapitalLetters) charSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const randomPassword = Array.from(
      { length: passwordLength },
      () => generateRandomChar(charSet)
    ).join("");

    setPassword(randomPassword);

    if (navigator.clipboard.writeText(randomPassword)) {
      toast("Password copied to your clipboard");
    }
  };

  const handleSpecialCharsChange = () => {
    setUseSpecialChars(!useSpecialChars);
  };

  const handleDigitsChange = () => {
    setUseDigits(!useDigits);
  };

  const handleCapitalLettersChange = () => {
    setUseCapitalLetters(!useCapitalLetters);
  };

  const handleSliderChange = (event, value) => {
    setPasswordLength(value);
  };

  const handleCopyClick = () => {
    if (navigator.clipboard.writeText(password)) {
      toast("Password copied to your clipboard");
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };


  return (
    <div>
      
      <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero | <span className='appnm'>Password Generator</span>
          </Typography>
          <div>
            <Button color="inherit" component={Link} to="/URLanalysis" className="nav-link">
              URL Threat Analysis
            </Button>
            <Button color="inherit" component={Link} to="/whois" className="nav-link">
              Whois Info
            </Button>
            {/* <Button color="inherit" component={Link} to="/md5" className="nav-link">
              MD5 Hash Generator
            </Button>
            <Button color="inherit" component={Link} to="/sha256" className="nav-link">
              SHA-256 Hash Generator
            </Button> */}
            {/* <Button color="inherit" component={Link} to="/whois" className="nav-link">
              WHOIS
            </Button>
            <Button color="inherit" component={Link} to="/ssl" className="nav-link">
              SSL Cert
            </Button>
            <Button color="inherit" component={Link} to="/Home" className="nav-link">
              Home
            </Button> */}
            <IconButton className="hamicn" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer className="hamdr" anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperComponent={Paper}
  // Add style to the Paper component
  PaperProps={{ style: { backgroundColor: '#1a80e5' } }} >
        <List>
          <ListItem button component={Link} to="/md5" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        MD5 Hash Generator
      </Typography>
    }
  />
          </ListItem>
          <ListItem button component={Link} className="hambm" to="/sha256" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        SHA-256 Hash Generator
      </Typography>
    }
  />
          </ListItem>
  <br />
  <br />
  <br />
  {/* <ListItem button component={Link} className="othr" to="/URLanalysis">
          <ListItemText
    primary={
      <Typography className="othr">
        A B O U T
      </Typography>
    }
  />
          </ListItem> */}
          <ListItem button component={Link} className="othr" to="/PrivacyPolicy" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        P R I V A C Y
      </Typography>
    }
  />
          </ListItem>
          {/* <ListItem button component={Link} className="othr" to="/URLanalysis" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        C O N T A C T
      </Typography>
    }
  />
          </ListItem> */}
        </List>
      </Drawer>

      <div className="background" style={{ paddingBottom: 30 }}>
      {/* <img src={logo} alt="Logo" className="logo" /> */}
        <h1 className="heading">Generate Secure Passwords in Seconds with the <br /> <span style={{ color: "#1a80e5" }}>Easy-to-Use PassZero Tool.</span></h1>
        {/* <h2 className="heading2">Generate secure, random passwords to stay safe online.</h2> */}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              id="generatedPassword"
              label="Generated Password"
              variant="filled"
              InputProps={{
                readOnly: true,
                style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyClick}>
                      <FileCopyIcon style={{ color: "#1a80e5" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              value={password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: "#1a80e5", marginTop: 16, fontFamily: "Poppins" }}>
              Entropy: {entropy.toFixed(2)} bits <br /> Estimated time to crack: {estimatedTimeToCrack} years
            </Typography>
            {qrCodeDataUrl && (
        <div style={{ border: '2px solid #1a80e5', padding: '0px', borderRadius: '4px', marginTop: '10px', display: 'inline-block' }}>
        <img src={qrCodeDataUrl} alt="QR Code" />
        </div>
      )}
          </Grid>
          <Grid item xs={12}>
            <Typography id="password-length-slider" gutterBottom style={{ color: "#ffffff", fontWeight: "bold" }}>
              Length: {passwordLength}
            </Typography>
            <Slider
              value={passwordLength}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={8}
              max={20}
              aria-labelledby="password-length-slider"
              style={{ color: "#ffffff", width: 230 }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="options">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useSpecialChars}
                    onChange={handleSpecialCharsChange}
                    style={{ color: "#ffffff" }}
                  />
                }
                label={<span style={{ color: "#ffffff", fontWeight: "bold" }}>Use Special Characters</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useDigits}
                    onChange={handleDigitsChange}
                    style={{ color: "#ffffff" }}
                  />
                }
                label={<span style={{ color: "#ffffff", fontWeight: "bold" }}>Use Digits</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useCapitalLetters}
                    onChange={handleCapitalLettersChange}
                    style={{ color: "#ffffff" }}
                  />
                }
                label={<span style={{ color: "#ffffff", fontWeight: "bold" }}>Use Capital Letters</span>}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className="generate-password"
              onClick={generatePassword}
              style={{ backgroundColor: "#1a80e5", color: "#000", borderRadius: "20px", fontWeight: "bold" }}
            >
              Generate password
            </Button>
          </Grid>
        </Grid>
        {/* <Link to="/md5" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            className="md5-hash-link"
            style={{ backgroundColor: '#A8EB12', color: '#000', borderRadius: '0px', fontWeight: 'bold', marginTop: '20px' }}
          >
            Go to MD5 Hash Generation
          </Button>
        </Link> */}
        <ToastContainer />
        {/* <Link to="/md5">Go to MD5 Hash Generation</Link> */}
      </div>
      <Box
      sx={{
        width: '100%',
        // maxWidth: '1200px',
        color: 'rgb(0, 0, 0)',
        backgroundColor: '#1a80e5',
        // padding: '31.5px',
      }}>
      <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="30px">
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="10px">
          <Box 
            sx={{
              // fontFamily: 'Open Sans',
              fontSize: '26px',
              display: 'inline-block',
              ' @media(max-width:991px)': { fontSize: '22px' },
              ' @media(max-width:479px)': { fontSize: '16px' },
            }}>
            <Typography className="faq-heading"
              variant="h1"
              sx={{
                display: 'inline',
                color: '#fff',
                marginRight: '10px',
                '&&': { marginRight: '10px' },
              }}>
              Frequently <br />asked
            </Typography>
            <Typography className="faq-heading"
              variant="h1"
              sx={{ color: '#121a21', display: 'inline' }}>
              questions.
            </Typography>
          </Box>
          <Box sx={{ fontFamily: 'Inter', display: 'inline-block' }}>
            <Typography className="faq-heading"
              variant="h2"
              sx={{
                display: 'inline',
                opacity: '0.7',
                fontSize: '20px',
                fontWeight: '500',
              }}>
              This FAQ guide is designed to enlighten users about the necessity and advantages of utilizing secure password generators.
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="0px">
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              Why do I need a secure password generator?
            </Typography>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
              A secure password generator helps create strong, complex passwords that are difficult for hackers to crack. This is crucial to protect your online accounts and sensitive information from unauthorized access.
            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How do hackers usually exploit weak passwords?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
              Hackers often use various methods like brute force attacks, dictionary attacks, or phishing to gain unauthorized access to accounts with weak passwords. A secure password generated by a reliable tool significantly reduces the risk of such attacks.
            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              What makes a password generated by a secure password generator strong?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
              A secure password generator creates passwords with a combination of uppercase and lowercase letters, numbers, and special characters. These complex combinations make it extremely challenging for attackers to guess or crack the password.
            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              Can't I just create a strong password on my own?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
              While it's possible to manually create strong passwords, a secure password generator ensures randomness and complexity that might be challenging to achieve manually. Moreover, it saves time and effort in generating unique passwords for each account.
            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How often should I change my passwords?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
              Regularly changing passwords is a good security practice. However, it's equally important to use strong, unique passwords. A secure password generator helps in both aspects by providing strong passwords and making it easier to change them regularly.
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
    </div>
    
  );
};

export default App;
