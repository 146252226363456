import React, { useState } from 'react';
import { TextField, Button, Typography, AppBar, Toolbar } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link} from "react-router-dom";
import 'os-browserify/browser';
import 'stream-browserify';
import './whois.css'
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Paper from '@mui/material/Paper';


const WhoisData = () => {
  const [domain, setDomain] = useState('');
  const [result, setResult] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleLookup = async () => {
    try {
      const response = await fetch(`https://ipwhois.app/json/${domain}`);
      const data = await response.json();

      // Format the data in a readable way
      if (data.success) {
        const formattedResult = `
          IP: ${data.ip}
          Type: ${data.type}
          Continent: ${data.continent} (${data.continent_code})
          Country: ${data.country} (${data.country_code})
          Country Capital: (${data.country_capital})
          Country Phone: (${data.country_phone})
          Country Neighbours: (${data.country_neighbours})
          Region: ${data.region}
          City: ${data.city}
          Latitude: ${data.latitude}
          Longitude: ${data.longitude}
          ASN: ${data.asn}
          Organization: ${data.org}
          ISP: ${data.isp}
          Timezone: ${data.timezone} (${data.timezone_name})
          Currency: ${data.currency} (${data.currency_code})
        `;
  
        setResult(formattedResult);
        toast.success('Whois Lookup successful');
      } else {
        setResult(`Error: ${data.message}`);
        toast.error('Whois Lookup failed');
      }
    } catch (error) {
      console.error(error);
      setResult('Error: Unable to perform Whois lookup.');
      toast.error('Whois Lookup failed');
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div>
     <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero | <span className='appnm'>W H O I S</span>
          </Typography>
          <div>
          <Button color="inherit" component={Link} to="/URLanalysis" className="nav-link">
              URL Threat Analysis
            </Button>
            <Button color="inherit" component={Link} to="/whois" className="nav-link">
              Whois Info
            </Button>
            <IconButton className="hamicn" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer className="hamdr" anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperComponent={Paper}
  // Add style to the Paper component
  PaperProps={{ style: { backgroundColor: '#1a80e5' } }} >
        <List>
          <ListItem button component={Link} to="/md5" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        MD5 Hash Generator
      </Typography>
    }
  />
          </ListItem>
          <ListItem button component={Link} className="hambm" to="/sha256" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        SHA-256 Hash Generator
      </Typography>
    }
  />
          </ListItem>
  <br />
  <br />
  <br />
  {/* <ListItem button component={Link} className="othr" to="/URLanalysis">
          <ListItemText
    primary={
      <Typography className="othr">
        A B O U T
      </Typography>
    }
  />
          </ListItem> */}
          <ListItem button component={Link} className="othr" to="/PrivacyPolicy" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        P R I V A C Y
      </Typography>
    }
  />
          </ListItem>
          {/* <ListItem button component={Link} className="othr" to="/URLanalysis" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        C O N T A C T
      </Typography>
    }
  />
          </ListItem> */}
        </List>
      </Drawer>
      <div className="background" style={{ paddingBottom: 218, paddingTop: 20 }}>
      <h1 className="heading">Use WHOIS lookup tool to find information <br />about <span style={{ color: "#1a80e5" }}>domain/IP ownership</span></h1>
        <div className="md5-hash-container">
          <TextField
            id="inputDomain"
            label="Enter Domain/IP"
            variant="filled"
            fullWidth
            margin="normal"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            InputProps={{
              readOnly: false,
              style: { color: '#1a80e5', border: '1px solid #ffffff', borderRadius: '8px' },
            }}
            InputLabelProps={{
              style: { color: '#ffffff' },
            }}
          />
          <Button
            variant="contained"
            style={{ backgroundColor: '#1a80e5', color: '#000', borderRadius: '20px', fontWeight: 'bold' }}
            onClick={handleLookup}
          >
            Perform Whois Lookup
          </Button>
          {result && (
            <div style={{ marginTop: 20 }}>
              <TextField
                id="whoisResult"
                label="Whois Result"
                variant="filled"
                fullWidth
                multiline
                InputProps={{
                  className: "whois-result",
                  readOnly: true,
                  style: { color: '#1a80e5', border: '1px solid #ffffff', borderRadius: '8px' },
                }}
                InputLabelProps={{
                  style: { color: '#ffffff' },
                }}
                value={result}
              />
            </div>
          )}
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default WhoisData;
