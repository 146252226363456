import React, { useState } from 'react';
import { Button, TextField, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import './App.css'
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import { Link} from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import "./SpaceGrotesk-Medium.ttf";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import BlockIcon from '@mui/icons-material/Block';
import SecurityIcon from '@mui/icons-material/Security';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Paper from '@mui/material/Paper';

const URLthreatlevel = () => {
  const [url, setUrl] = useState('');
  const [threatInfo, setThreatInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCheckThreat = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(`/.netlify/functions/check-threat?url=${url}`);
      const threatInfo = response.data;

      setThreatInfo(threatInfo);
    } catch (error) {
      console.error(error);
      setError('Error checking threat level');
    } finally {
      setLoading(false);
    }
  };

  
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderDashboardItem = (label, value, icon, color) => (
    <div className="dashboard-item" style={{ color }}>
      {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
      <span>{label}:</span> {typeof value === 'boolean' ? (value ? 'true' : 'false') : value}
    </div>
  );
  
  
  

  return (
    <div>
        <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero | <span className='appnm'>U R L  T H R E A T Analysis</span>
          </Typography>
          <div>
          <Button color="inherit" component={Link} to="/URLanalysis" className="nav-link">
              URL Threat Analysis
            </Button>
            <Button color="inherit" component={Link} to="/whois" className="nav-link">
              Whois Info
            </Button>
            {/* <Button color="inherit" component={Link} to="/whois" className="nav-link">
              WHOIS
            </Button>
            <Button color="inherit" component={Link} to="/ssl" className="nav-link">
              SSL Cert
            </Button>
            <Button color="inherit" component={Link} to="/Home" className="nav-link">
              Home
            </Button> */}
            <IconButton className="hamicn" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer className="hamdr" anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperComponent={Paper}
  // Add style to the Paper component
  PaperProps={{ style: { backgroundColor: '#1a80e5' } }} >
        <List>
          <ListItem button component={Link} to="/md5" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        MD5 Hash Generator
      </Typography>
    }
  />
          </ListItem>
          <ListItem button component={Link} className="hambm" to="/sha256" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        SHA-256 Hash Generator
      </Typography>
    }
  />
          </ListItem>
  <br />
  <br />
  <br />
  {/* <ListItem button component={Link} className="othr" to="/URLanalysis">
          <ListItemText
    primary={
      <Typography className="othr">
        A B O U T
      </Typography>
    }
  />
          </ListItem> */}
          <ListItem button component={Link} className="othr" to="/PrivacyPolicy" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        P R I V A C Y
      </Typography>
    }
  />
          </ListItem>
          {/* <ListItem button component={Link} className="othr" to="/URLanalysis" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        C O N T A C T
      </Typography>
    }
  />
          </ListItem> */}
        </List>
      </Drawer>
    <div className="background" style={{ paddingBottom: 232 }}>
                <h1 className="heading">Ensure the safety of any URL with <br /> instant <span style={{ color: "#1a80e5" }}>threat analysis.</span></h1>

      <TextField
        id="url"
        label="Enter URL"
        variant="filled"
        onChange={(e) => setUrl(e.target.value)}
        InputProps={{
          readOnly: false,
          style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
        }}
        InputLabelProps={{
          style: { color: "#ffffff" },
        }}
        style={{ marginBottom: '10px' }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckThreat}
        disabled={loading || !url.trim()}
        style={{ marginBottom: '20px', backgroundColor: "#1a80e5", color: "#000", borderRadius: "20px", fontWeight: "bold" }}
      >
        {loading ? <CircularProgress size={24} /> : 'Check Threat Level'}
      </Button>

      {error && (
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {threatInfo && (
        <div>
        {/* // <Card elevation={3} style={{ padding: '20px', borderRadius: '15px' }}>
        //   <CardContent> */}
        <div className="dashboard-container">
  <div className="dashboard-section-1">
        <Typography className="dashboard-heading" variant="h4" gutterBottom style={{ color: "#1a80e5" }}>
              Target Information <InfoIcon style={{ color: '#fff' }}/>
            </Typography>
            {/* <Typography className="dashboard-item" variant="body1" gutterBottom>
              Domain: {threatInfo.domain}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
                IP address: {threatInfo.ip_address}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
                Server: {threatInfo.server}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Content Type: {threatInfo.content_type}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Domain Rank: {threatInfo.domain_rank}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Country Code: {threatInfo.country_code}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Language Code: {threatInfo.language_code}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Category: {threatInfo.category}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Hosted content: {threatInfo.hosted_content ? 'true' : 'false'}
            </Typography> */}
            {/* <Typography className="dashboard-item" variant="body1" gutterBottom>
            DNS Valid: {threatInfo.dns_valid ? 'true' : 'false'}
            </Typography> */}
            {renderDashboardItem('Domain', threatInfo.domain,'', '#4CAF50')}
            {renderDashboardItem('IP address', threatInfo.ip_address,'', '#4CAF50')}
            {renderDashboardItem('Server', threatInfo.server,'', '#4CAF50')}
            {renderDashboardItem('Content Type', threatInfo.content_type,'', '#4CAF50')}
            {renderDashboardItem('Domain Rank', threatInfo.domain_rank,'', '#4CAF50')}
            {renderDashboardItem('Country Code', threatInfo.country_code,'', '#4CAF50')}
            {renderDashboardItem('Language Code', threatInfo.language_code,'', '#4CAF50')}
            {renderDashboardItem('Category', threatInfo.category,'', '#4CAF50')}
            {renderDashboardItem('Hosted Content', threatInfo.hosted_content, threatInfo.hosted_content ? <CheckCircleIcon /> : <BlockIcon />, '#4CAF50')}
            {renderDashboardItem('DNS Valid', threatInfo.dns_valid, threatInfo.dns_valid ? <CheckCircleIcon /> : <BlockIcon />, '#4CAF50')}
            {renderDashboardItem('SPF Record', threatInfo.spf_record, threatInfo.spf_record ? <CheckCircleIcon /> : <BlockIcon />, '#4CAF50')}
            {renderDashboardItem('DMARC Record', threatInfo.dmarc_record, threatInfo.dmarc_record ?  <CheckCircleIcon /> : <BlockIcon />, '#4CAF50')}

            {/* <Typography className="dashboard-item" variant="body1" gutterBottom>
            SPF Record: {threatInfo.spf_record ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            DMARC Record: {threatInfo.dmarc_record ? 'true' : 'false'}
            </Typography> */}
            </div>
            <div className="dashboard-section-2">
            <Typography className="dashboard-heading" variant="h4" gutterBottom style={{ color: "#1a80e5" }}>
              Target Threat Intelligence <SecurityIcon style={{ color: '#fff' }}/>
            </Typography>
            {/* <Typography className="dashboard-item" variant="body1" gutterBottom>
              Unsafe ?: {threatInfo.unsafe ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Risk Score: {threatInfo.risk_score}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Parking: {threatInfo.parking ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Spamming: {threatInfo.spamming ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Malware: {threatInfo.malware ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
              Phishing: {threatInfo.phishing ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            Suspicious: {threatInfo.suspicious ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            Adult: {threatInfo.adult ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            Redirected: {threatInfo.redirected ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            Short Link Redirect: {threatInfo.short_link_redirect ? 'true' : 'false'}
            </Typography>
            <Typography className="dashboard-item" variant="body1" gutterBottom>
            Risky TLD: {threatInfo.risky_tld ? 'true' : 'false'}
            </Typography> */}
            {renderDashboardItem('Unsafe', threatInfo.unsafe, <WarningIcon />, '#FFD700')}
        {renderDashboardItem('Risk Score', threatInfo.risk_score, <SecurityIcon />, '#4CAF50')}
        {renderDashboardItem('Parking', threatInfo.parking, <ErrorIcon />, '#4CAF50')}
        {renderDashboardItem('Spamming', threatInfo.spamming, <ErrorIcon />, '#4CAF50')}
        {renderDashboardItem('Malware', threatInfo.malware, <ErrorIcon />, '#4CAF50')}
        {renderDashboardItem('Phishing', threatInfo.phishing, <ErrorIcon />, '#4CAF50')}
        {renderDashboardItem('Suspicious', threatInfo.suspicious, <ReportProblemIcon />, '#4CAF50')}
        {renderDashboardItem('Adult', threatInfo.adult, <ReportProblemIcon />, '#4CAF50')}
        {renderDashboardItem('Redirected', threatInfo.redirected, <InfoIcon />, '#4CAF50')}
        {renderDashboardItem('Short Link Redirect', threatInfo.short_link_redirect, <InfoIcon />, '#4CAF50')}
        {renderDashboardItem('Risky TLD', threatInfo.risky_tld, <InfoIcon />, '#4CAF50')}
            </div>
            </div>
            
            
            {/* Add more information as needed */}
        {/* //   </CardContent>
        // </Card> */}
        </div>
      )}
    </div>
    
    </div>
    
  );
};

export default URLthreatlevel;
