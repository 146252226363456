import React from 'react';
import { Typography, Container, CssBaseline, AppBar, Button, Toolbar } from '@mui/material';
import { Link} from "react-router-dom";
import './ppolicy.css';
const PrivacyPolicy = () => {
  return (
    <div>
    <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero
          </Typography>
          <div>
            <Button color="inherit" component={Link} to="/md5" className="nav-link">
              MD5 Hash Generator
            </Button>
            <Button color="inherit" component={Link} to="/sha256" className="nav-link">
              SHA-256 Hash Generator
            </Button>
            <Button color="inherit" component={Link} to="/ssl" className="nav-link">
              SSL/TLS Certificate Lookup
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    <div style={{ backgroundColor: '#121a21', paddingBottom: '30', color: '#ffffff' }}>
      <CssBaseline />
      <Container maxWidth="md" style={{ paddingBottom: 20 }}>
        <Typography className="ppolicy" variant="h4" style={{color: '#1a80e5'}}gutterBottom>
          Privacy Policy
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
          Welcome to PassZero, a simple online tool that provides various utilities, including MD5 and SHA-256 hash generation.
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
        This Privacy Policy describes Our policies and procedures on the collection,
use and disclosure of Your information when You use the Service and tells You
about Your privacy rights and how the law protects You.

We use Your Personal data to provide and improve the Service. By using the
Service, You agree to the collection and use of information in accordance with
this Privacy Policy. 
        </Typography>
        <Typography className="ppolicy" variant="h5" style={{color: '#1a80e5'}} gutterBottom>
        Interpretation and Definitions
        </Typography>
        <Typography className="ppolicy" variant="h6" style={{color: '#1a80e5'}} gutterBottom>
        Interpretation
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
        The words of which the initial letter is capitalized have meanings defined
under the following conditions. The following definitions shall have the same
meaning regardless of whether they appear in singular or in plural.        </Typography>
<Typography className="ppolicy" variant="h6" style={{color: '#1a80e5'}} gutterBottom>
Definitions
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
        For the purposes of this Privacy Policy:

  <ul className="ppolicy" >Account means a unique account created for You to access our Service or
    parts of our Service.</ul>

    <ul className="ppolicy">Affiliate means an entity that controls, is controlled by or is under
    common control with a party, where "control" means ownership of 50% or
    more of the shares, equity interest or other securities entitled to vote
    for election of directors or other managing authority.</ul>

    <ul className="ppolicy">PassZero (referred to as either "the PassZero", "We", "Us" or "Our" in this
    Agreement) refers to PassZero.</ul>

    <ul className="ppolicy">Cookies are small files that are placed on Your computer, mobile device or
    any other device by a website, containing the details of Your browsing
    history on that website among its many uses.</ul>

    <ul className="ppolicy">Country refers to: United Kingdom</ul>

    <ul className="ppolicy">Device means any device that can access the Service such as a computer, a
    cellphone or a digital tablet.</ul>

    <ul className="ppolicy">Personal Data is any information that relates to an identified or
    identifiable individual.</ul>

    <ul className="ppolicy">Service refers to the Website.</ul>

    <ul className="ppolicy">Service Provider means any natural or legal person who processes the data
    on behalf of the PassZero. It refers to third-party companies or
    individuals employed by the PassZero to facilitate the Service, to provide
    the Service on behalf of the PassZero, to perform services related to the
    Service or to assist the PassZero in analyzing how the Service is used.</ul>

    <ul className="ppolicy">Usage Data refers to data collected automatically, either generated by the
    use of the Service or from the Service infrastructure itself (for example,
    the duration of a page visit).</ul>

    <ul className="ppolicy">Website refers to PassZero, accessible from https://passzero.me</ul>

    <ul className="ppolicy">You means the individual accessing or using the Service, or the PassZero,
    or other legal entity on behalf of which such individual is accessing or
    using the Service, as applicable.</ul>
        </Typography>
        <Typography className="ppolicy" variant="h5" style={{color: '#1a80e5'}} gutterBottom>
          Cookies
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
          PassZero may use cookies to enhance the user experience. Cookies are small text files stored on your device that help improve website functionality.
        </Typography>
        <Typography className="ppolicy" variant="h5" style={{color: '#1a80e5'}} gutterBottom>
          Third-Party Links
        </Typography>
        <Typography className="ppolicy" variant="body1" paragraph>
          PassZero may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
        </Typography>
      </Container>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
