import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./md5.css";
import { Link } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from '@mui/material/Paper';

const SHA256HashPage = () => {
  const [inputText, setInputText] = useState("");
  const [sha256Hash, setSHA256Hash] = useState("**********************");
  const [drawerOpen, setDrawerOpen] = useState(false);


  const generateSHA256Hash = async () => {
    if (inputText.trim() === "") {
      toast.error("Please enter a valid text");
      return;
    }

    // Convert the input text to a buffer
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(inputText);

    // Use the Web Crypto API to generate SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    setSHA256Hash(hashHex);
    toast.success("SHA-256 Hash generated successfully");
  };

  const handleCopyClick = () => {
    if (navigator.clipboard.writeText(sha256Hash)) {
      toast("Hash copied to your clipboard");
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div>
      <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero | <span className='appnm'>S H A 2 5 6</span>
          </Typography>
          <div>
          <Button color="inherit" component={Link} to="/URLanalysis" className="nav-link">
              URL Threat Analysis
            </Button>
            <Button color="inherit" component={Link} to="/whois" className="nav-link">
              Whois Info
            </Button>
            {/* <Button color="inherit" component={Link} to="/whois" className="nav-link">
              WHOIS
            </Button> */}
            <IconButton className="hamicn" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer className="hamdr" anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperComponent={Paper}
  // Add style to the Paper component
  PaperProps={{ style: { backgroundColor: '#1a80e5' } }} >
        <List>
          <ListItem button component={Link} to="/md5" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        MD5 Hash Generator
      </Typography>
    }
  />
          </ListItem>
          <ListItem button component={Link} className="hambm" to="/sha256" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        SHA-256 Hash Generator
      </Typography>
    }
  />
          </ListItem>
  <br />
  <br />
  <br />
  {/* <ListItem button component={Link} className="othr" to="/URLanalysis">
          <ListItemText
    primary={
      <Typography className="othr">
        A B O U T
      </Typography>
    }
  />
          </ListItem> */}
          <ListItem button component={Link} className="othr" to="/PrivacyPolicy" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        P R I V A C Y
      </Typography>
    }
  />
          </ListItem>
          {/* <ListItem button component={Link} className="othr" to="/URLanalysis" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        C O N T A C T
      </Typography>
    }
  />
          </ListItem> */}
        </List>
      </Drawer>
      <div className="background" style={{ paddingBottom: 161 }}>
        <h1 className="heading">
          Simple and free online tool that generates <br />
          random <span style={{ color: "#1a80e5" }}>SHA-256 Hash</span> for Text
        </h1>

        <div className="md5-hash-container">
          <TextField
            id="inputText"
            label="Enter Text"
            variant="filled"
            fullWidth
            margin="normal"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            InputProps={{
              readOnly: false,
              style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
            }}
            InputLabelProps={{
              style: { color: "#ffffff" },
            }}
          />
          <Button
            variant="contained"
            className="generate-md5-hash"
            onClick={generateSHA256Hash}
            style={{ backgroundColor: "#1a80e5", color: "#000", borderRadius: "20px", fontWeight: "bold" }}
          >
            Generate SHA-256 Hash
          </Button>
          {sha256Hash && (
            <div className="md5-hash-result">
              <TextField
                id="generatedSHA256Hash"
                label="Generated SHA-256 Hash"
                variant="filled"
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopyClick}>
                        <FileCopyIcon style={{ color: "#1a80e5" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "#ffffff" },
                }}
                value={sha256Hash}
              />
            </div>
          )}
        </div>

        <ToastContainer />
      </div>
      <Box
      sx={{
        width: '100%',
        maxWidth: '1200px',
        color: 'rgb(0, 0, 0)',
        backgroundColor: '#1a80e5',
        padding: '31.5px',
      }}>
      <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="30px">
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="10px">
          <Box 
            sx={{
              // fontFamily: 'Open Sans',
              fontSize: '26px',
              display: 'inline-block',
              ' @media(max-width:991px)': { fontSize: '22px' },
              ' @media(max-width:479px)': { fontSize: '16px' },
            }}>
            <Typography className="faq-heading"
              variant="h1"
              sx={{
                display: 'inline',
                color: '#fff',
                marginRight: '10px',
                '&&': { marginRight: '10px' },
              }}>
              All about <br />
            </Typography>
            <Typography className="faq-heading"
              variant="h1"
              sx={{ color: '#121a21', display: 'inline' }}>
              SHA-256 Hash.
            </Typography>
          </Box>
          <Box sx={{ fontFamily: 'Inter', display: 'inline-block' }}>
            <Typography className="faq-heading"
              variant="h2"
              sx={{
                display: 'inline',
                opacity: '0.7',
                fontSize: '20px',
                fontWeight: '500',
              }}>
              This FAQ is your go-to manual for understanding SHA-256 hash generators. From basics to practical tips, it's all here in simple words. So, whether you're just curious or aiming to boost your digital security, start exploring the power of SHA-256 in a way that makes sense to everyone.
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="0px">
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              What is a SHA-256 hash generator?
            </Typography>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
A SHA-256 hash generator is a tool that uses the SHA-256 (Secure Hash Algorithm 256-bit) cryptographic hash function to convert input data into a fixed-length string of characters, known as a hash. This process is a one-way function, making it computationally infeasible to reverse the transformation.            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How does SHA-256 hashing enhance security?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
SHA-256 is a cryptographic hash function known for its strength and resistance to collision attacks. It produces a unique fixed-size hash for each unique input, making it extremely difficult for attackers to recreate the original data from the hash.            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              Can I reverse a SHA-256 hash to retrieve the original data?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
No, one of the fundamental properties of cryptographic hash functions is their irreversibility. It is practically impossible to reverse a SHA-256 hash to obtain the original input data. This property enhances the security of hashed information.            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How long is the output of a SHA-256 hash?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
The output of a SHA-256 hash is always 256 bits, or 64 characters when represented in hexadecimal format.            </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
Can I use SHA-256 hashes for data integrity verification?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
Absolutely. SHA-256 hashes are commonly used to verify the integrity of files by comparing the hash value before and after data transfer. If the hashes match, it indicates that the data has not been altered during the transfer process.            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
    </div>
  );
};

export default SHA256HashPage;
