// MD5HashPage.jsx
import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import md5 from "md5";
import "./md5.css";
import { Link} from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy"; // Import the FileCopyIcon
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from '@mui/material/Paper';

const MD5HashPage = () => {
  const [inputText, setInputText] = useState("");
  const [md5Hash, setMD5Hash] = useState("**********************");
  const [drawerOpen, setDrawerOpen] = useState(false);


  const generateMD5Hash = () => {
    if (inputText.trim() === "") {
      toast.error("Please enter a valid text");
      return;
    }

    // Use the md5 library for secure MD5 hashing
    const hashedText = md5(inputText);

    setMD5Hash(hashedText);
    toast.success("MD5 Hash generated successfully");
  };

  const handleCopyClick = () => {
    if (navigator.clipboard.writeText(md5Hash)) {
      toast("Password copied to your clipboard");
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div>
      <AppBar position="static" className="navbar" style={{ backgroundColor: "#121a21", fontFamily: "SpaceGrotesk-Medium" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component={Link} to="/" className="nav-link">
            PassZero | <span className='appnm'>M D 5</span>
          </Typography>
          <div>
          <Button color="inherit" component={Link} to="/URLanalysis" className="nav-link">
              URL Threat Analysis
            </Button>
            <Button color="inherit" component={Link} to="/whois" className="nav-link">
              Whois Info
            </Button>
            {/* <Button color="inherit" component={Link} to="/whois" className="nav-link">
              WHOIS
            </Button> */}
            <IconButton className="hamicn" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer className="hamdr" anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperComponent={Paper}
  // Add style to the Paper component
  PaperProps={{ style: { backgroundColor: '#1a80e5' } }} >
        <List>
          <ListItem button component={Link} to="/md5" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        MD5 Hash Generator
      </Typography>
    }
  />
          </ListItem>
          <ListItem button component={Link} className="hambm" to="/sha256" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="hambm">
        SHA-256 Hash Generator
      </Typography>
    }
  />
          </ListItem>
  <br />
  <br />
  <br />
  {/* <ListItem button component={Link} className="othr" to="/URLanalysis">
          <ListItemText
    primary={
      <Typography className="othr">
        A B O U T
      </Typography>
    }
  />
          </ListItem> */}
          <ListItem button component={Link} className="othr" to="/PrivacyPolicy" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        P R I V A C Y
      </Typography>
    }
  />
          </ListItem>
          {/* <ListItem button component={Link} className="othr" to="/URLanalysis" onClick={toggleDrawer(false)}>
          <ListItemText
    primary={
      <Typography className="othr">
        C O N T A C T
      </Typography>
    }
  />
          </ListItem> */}
        </List>
      </Drawer>
      <div className="background" style={{ paddingBottom: 161 }}>
        {/* <img src={logo} alt="Logo" className="logo" /> */}
        <h1 className="heading">Simple and free online tool that generates <br />random <span style={{ color: "#1a80e5" }}>MD5 Hash</span> for Text</h1>

        <div className="md5-hash-container">
          <TextField
            id="inputText"
            label="Enter Text"
            variant="filled"
            fullWidth
            margin="normal"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            InputProps={{
              readOnly: false,
              style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
            }}
            InputLabelProps={{
              style: { color: "#ffffff" },
            }}
          />
          <Button
            variant="contained"
            className="generate-md5-hash"
            onClick={generateMD5Hash}
            style={{ backgroundColor: "#1a80e5", color: "#000", borderRadius: "20px", fontWeight: "bold", }}
          >
            Generate MD5 Hash
          </Button>
          {md5Hash && (
            <div className="md5-hash-result">
            <TextField
              id="generatedmd5Hash"
              label="Generated MD5 Hash"
              variant="filled"
              fullWidth
              InputProps={{
                readOnly: true,
                style: { color: "#1a80e5", border: "1px solid #ffffff", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyClick}>
                      <FileCopyIcon style={{ color: "#1a80e5" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              value={md5Hash}
            />              
            </div>
          )}
        </div>

        <ToastContainer />
      </div>
      <Box
      sx={{
        width: '100%',
        maxWidth: '1200px',
        color: 'rgb(0, 0, 0)',
        backgroundColor: '#1a80e5',
        padding: '31.5px',
      }}>
      <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="30px">
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="10px">
          <Box 
            sx={{
              // fontFamily: 'Open Sans',
              fontSize: '26px',
              display: 'inline-block',
              ' @media(max-width:991px)': { fontSize: '22px' },
              ' @media(max-width:479px)': { fontSize: '16px' },
            }}>
            <Typography className="faq-heading"
              variant="h1"
              sx={{
                display: 'inline',
                color: '#fff',
                marginRight: '10px',
                '&&': { marginRight: '10px' },
              }}>
              All about <br />
            </Typography>
            <Typography className="faq-heading"
              variant="h1"
              sx={{ color: '#121a21', display: 'inline' }}>
              MD5 Hash.
            </Typography>
          </Box>
          <Box sx={{ fontFamily: 'Inter', display: 'inline-block' }}>
            <Typography className="faq-heading"
              variant="h2"
              sx={{
                display: 'inline',
                opacity: '0.7',
                fontSize: '20px',
                fontWeight: '500',
              }}>
              This FAQ sheds light on the vulnerabilities associated with MD5. While once a widely-used hashing algorithm, MD5 is now considered cryptographically broken due to its susceptibility to collision attacks. Discover why it's crucial to consider more secure alternatives like <Link to="/sha256" style={{ textDecoration: 'none', color: 'white' }}>SHA-256</Link> or bcrypt, especially for applications requiring a higher level of security.
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', width: '100%' }} spacing="0px">
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              What is an MD5 hash generator?
            </Typography>
            <Typography
              variant="h5" className="faq-heading"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
An MD5 hash generator is a tool that utilizes the MD5 (Message Digest Algorithm 5) cryptographic hash function to produce a fixed-size hash value from input data. The MD5 algorithm is widely used for checksums, data integrity verification, and storing hashed passwords.
           </Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How does MD5 hashing enhance security?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
MD5 hashing transforms input data into a fixed-size hash, making it challenging for attackers to reverse the process and obtain the original data. However, it's important to note that MD5 is now considered cryptographically broken and unsuitable for further use due to vulnerabilities.
</Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              Can I reverse an MD5 hash to retrieve the original data?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
While theoretically, MD5 hashes are irreversible, MD5 is no longer considered secure due to vulnerabilities that allow for collision attacks. It is recommended to use more secure hash functions like SHA-256 for applications requiring a higher level of security.
</Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
              How long is the output of an MD5 hash?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
The output of an MD5 hash is always 128 bits, or 32 characters when represented in hexadecimal format.</Typography>
          </Box>
          <Divider
            sx={{ height: '1px' }}
            color="#edededff"
            style={{ width: '100%' }}></Divider>
          <Box
            sx={{
              fontFamily: 'Inter',
              display: 'flex',
              padding: '20px 0px',
              width: '100%',
              color: 'rgb(0, 0, 0)',
              justifyContent: 'space-between',
              ' @media(max-width:991px)': {
                flexDirection: 'column',
                gap: '10px',
              },
              ' @media(max-width:479px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                width: '50%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { width: '100%' },
              }}>
Is MD5 vulnerable to any attacks?
            </Typography>
            <Typography className="faq-heading"
              variant="h5"
              sx={{
                fontWeight: '500',
                opacity: '0.8',
                width: '70%',
                ' @media(max-width:991px)': { width: '100%' },
                ' @media(max-width:479px)': { fontSize: '16px', width: '100%' },
              }}>
Yes, MD5 is vulnerable to collision attacks, where two different inputs produce the same hash value. This compromises the integrity of the hashing process. Due to these vulnerabilities, MD5 is no longer considered secure for cryptographic purposes.
</Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
    </div>
  );
};

export default MD5HashPage;
