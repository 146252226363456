// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import MD5HashPage from './MD5HashPage';
import WhoisData from './WhoisData';
import SHA256HashPage from './SHA256HashPage';
import URLthreatlevel from './URLthreatlevel';
// import SSLCertificateData from './SSLCertificateData'
// import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

ReactDOM.createRoot(root).render(
  <React.StrictMode>
<Router>
  <Routes>
    <Route path="/md5" element={<MD5HashPage />} />
    <Route path="/sha256" element={<SHA256HashPage />} />
    <Route path="/URLanalysis" element={<URLthreatlevel />} />
    <Route path="/whois" element={<WhoisData />} />
    {/* <Route path="/ssl" element={<SSLCertificateData />} /> */}
    {/* <Route path="/Home" element={<Home />} /> */}
    <Route path="/" element={<App />} />
    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
  </Routes>
</Router>
  </React.StrictMode>,
);

reportWebVitals();
